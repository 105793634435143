import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import Grid from "@material-ui/core/Grid";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../jss/landing-webdesign.js";

const useStyles = makeStyles({
  ...styles,
  imageInfo5: {
    width: "100%",
    height: "300px",
    boxShadow: "0 8px 6px -6px black",
    display: "block",
    borderRadius: "3px",
    position: "relative",
    top: "120px",

    "@media(min-width: 768px) and (max-width: 1024px)": {
      width: "100%",
      height: "310px",
      position: "relative",
      top: "40px",
    },
    "@media(max-width: 500px)": {
      maxWidth: "100%",
      maxHeight: "210px",
      display: "flex",
      marginLeft: "30px",
    },
  },
});
export default function SectionCards() {
  const classes = useStyles();

  return (
    <div className={classNames(classes.section2)}>
      <div>
        <Grid container>
          <Grid item lg={6} xs={12} sm={10} md={7}>
            <div className={classes.containerLeak}>
              <Grid item lg={12} xs={12} sm={10} md={12}>
                <h2 className={classes.title3}>WHY CHOOSE US</h2>
              </Grid>
              <Grid item lg={10} xs={10} sm={10} md={12}>
                <p className={classes.paragraph}>
                  Finding a plumber in Oakville that is licensed, insured,
                  qualified, and experienced is a difficult task. GoPro
                  Plumbing’s work is based on high industry standards,
                  delivering nothing less than perfection. Not many people like
                  to deal with plumbing and that’s why we are here! We tick all
                  the boxes when it comes to licensing, insurance, and
                  certification.
                </p>
                <p className={classes.paragraph}>
                  Our team of expert plumbers are here to resolve your plumbing
                  problems immediately. We not only provide outstanding
                  workmanship but also make sure that our clients are fully
                  satisfied. Here are the 3 most important benefits of choosing
                  us as your contractors:
                </p>
                <ul className={classes.paragraph}>
                  <li>
                    24/7 emergency plumbing service with certified and licensed
                    plumbers in Oakville at your disposal
                  </li>
                  <li>
                    Implementing modern materials and technological solutions in
                    our projects
                  </li>
                  <li>
                    100% dedication and hard work for every project no matter
                    its scope or location
                  </li>
                </ul>
                <p className={classes.paragraph}>
                  Why use old methods to maintain and restore your plumbing
                  system? Choose us as your new plumber in Oakville and secure
                  the essential parts of your property.
                </p>
              </Grid>
            </div>
          </Grid>
          <Grid item lg={5} xs={12} sm={10} md={7}>
            <Grid
              container
              justify="right"
              direction="column"
              alignItem="right"
              alignContent="right"
            >
              <Grid item lg={10} xs={10} sm={12} md={12}>
                <div>
                  <iframe
                    className={classes.imageInfo5}
                    src="https://www.youtube.com/embed/_1GnTnaxS1I"
                    frameBorder="0"
                    allow="encrypted-media; gyroscope;"
                    allowFullScreen
                    title="Leak detection system"
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
