import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import Grid from "@material-ui/core/Grid";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../jss/landing-webdesign.js";

const useStyles = makeStyles({
  ...styles,

  image2: {
    width: "50%",
    height: "400px",
    boxShadow: "0 8px 6px -6px black",
    display: "block",
    borderRadius: "3px",
    position: "relative",
    top: "40px",

    "@media(min-width: 768px) and (max-width: 1024px)": {
      width: "50%",
      height: "350px",
      position: "relative",
      top: "40px",
    },
    "@media(max-width: 500px)": {
      width: "auto",
      height: "210px",
      display: "flex",
    },
  },
});
export default function SectionCards() {
  const classes = useStyles();

  return (
    <div style={{ padding: "60px 0px 0px 0px" }}>
      <div className={classNames(classes.section, classes.sectionDark)}>
        <Grid container justify="center" alignItem="center">
          <Grid item lg={10} xs={10} sm={10} md={7}>
            <h2 align="center" className={classes.title}>
              RESIDENTIAL SERVICES
            </h2>
          </Grid>
          <Grid item lg={5} xs={10} sm={10} md={7}>
            <p className={classes.paragraph}>
              Residential plumbing issues are quite common, especially in harsh
              winters. From burst pipes to a leaking sump pump, at GoPro
              Plumbing, we will make sure that problems are quickly. Here is how
              we do it:
            </p>
            <ul className={classes.paragraph}>
              <li>
                24/7 emergency plumbing service with a certified plumber in
                Brampton at your disposal
              </li>
              <li>Fixture upgrades</li>
              <li>
                Implementing modern materials and technological solutions in our
                projects
              </li>
              <li>
                100% dedication and hard work for every project no matter its
                scope or location
              </li>
            </ul>
            <p className={classes.paragraph}>
              Contact us today to properly fix and secure your plumbing system.
              Our team of licensed and experienced plumbers in Brampton can help
              you with:
            </p>
            <ul className={classes.paragraph}>
              <li>
                Fixture installation, faucet repairs, kitchen sink & kitchen
                faucet installation
              </li>
              <li>New construction and consultations</li>
              <li>Backwater valve installation</li>
              <li>
                Plumbing drains services, sewer backup & water main replacement
              </li>
              <li>PhynPlus water monitoring</li>
              <li>Kitec removal</li>
              <li>Watermain replacement</li>
              <li>Troubleshooting including clogged drain and leaking pipe</li>
            </ul>
          </Grid>
        </Grid>
        <Grid container justify="center" alignItem="center">
          <Grid item lg={10} xs={10} sm={10} md={7}>
            <h2 align="center" className={classes.title}>
              COMMERCIAL PROJECTS
            </h2>
          </Grid>
          <Grid item lg={5} xs={10} sm={10} md={7}>
            <p className={classes.paragraph}>
              In commercial properties, a lot more people depend on the plumbing
              system’s condition. Unfortunately, because of frequent usage,
              plumbing issues are bound to occur in such properties.
            </p>
            <p className={classes.paragraph}>
              Even the smallest problem in a commercial plumbing system can put
              your business a step behind schedule. If not quickly resolved,
              these problems can escalate into major plumbing issues. To avoid
              major issues and continue working at your usual paste, it’s best
              to contact experts as soon as possible.
            </p>
            <p className={classes.paragraph}>
              As an expert plumber in Brampton, we are working round the clock
              to help local commercial properties stay on track. We offer
              reliable and proven workmanship, modern materials and solutions,
              and loyal customer service; helping with:
            </p>
            <ul className={classes.paragraph}>
              <li>Troubleshooting</li>
              <li>Kitec removal and replacement</li>
              <li>Riser and watermain replacement</li>
              <li>Fixture upgrades</li>
              <li>Drain cleaning and inspection</li>
            </ul>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
