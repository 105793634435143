import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Grid from "@material-ui/core/Grid";

import Certified from "../../assets/home/toronto-plumber.svg";
import Design from "../../assets/home/toronto-plumbers.svg";
import styles from "../../jss/productStyle.js";

const useStyles = makeStyles({
  ...styles,
  paragraph: {
    color: "#212121",
    fontSize: "16px",
    fontFamily: "Open Sans",
    fontWeight: "400",
    lineHeight: "32px",
    textAlign: "left",
  },
  section2: {
    padding: "70px 0",
  },
  paragraph2: {
    color: "#212121",
    fontSize: "16px",
    fontFamily: "poppins",
    fontWeight: "400",
    lineHeight: "32px",
    textAlign: "left",
    "@media screen and (min-width:1920px)": {
      marginTop: "10px",
    },
  },
  imageContainer: {
    padding: "60px 60px 0px 60px",
    marginTop: "40px",
    "@media screen and (max-width:500px)": {
      padding: "0px 60px 0px 60px",
    },
    "@media(min-width: 768px) and (max-width: 1024px)": {
      order: "1",
    },
  },
  orderContainer: {
    "@media screen and (max-width:500px)": {
      order: "2",
    },
    "@media(min-width: 768px) and (max-width: 1024px)": {
      order: "2",
    },
  },
});

export default function ProductSection() {
  const classes = useStyles();

  return (
    <div>
      <Grid
        container
        justify="center"
        alignContent="center"
        alignItems="center"
        spacing={4}
      >
        <Grid item lg={3} xs={10} sm={5} md={5}>
          <div align="center" className={classes.imageContainer}>
            <Certified alt="toronto-plumber" height="100%" width="100%" />
          </div>
        </Grid>

        <Grid item xs={10} sm={10} md={10} lg={7}>
          <h2 className={classes.title}> Brampton Plumber </h2>
          <p className={classes.paragraph2}>
            GoPro Plumbing is a professional plumbing company that operates in
            Brampton, Ontario. With 24/7 emergency service and a team of
            licensed plumbers, GoPro plumbing can quickly take action and help
            resolve any plumbing-related issue. We have 15 years of experience
            in both commercial and residential projects, finishing all projects
            on time and on budget.
          </p>
          <p className={classes.paragraph2}>
            Choose GoPro Plumbing as your new go-to plumber in Brampton! Contact
            us to help you solve your plumbing problems! From replacing old
            water mains to drain cleaning and inspection, the team at GroPro can
            help with all your plumbing repairs.
          </p>
        </Grid>

        <Grid
          item
          xs={10}
          sm={10}
          md={10}
          lg={7}
          className={classes.orderContainer}
        >
          <h2 className={classes.title}> Design & Build</h2>
          <p className={classes.paragraph2}>
            GoPro Plumbing has experience in large construction projects. During
            the design phase, we will provide recommendations that will save you
            both time and money when constructing your new plumbing system. We
            are licensed and insured plumbers who only provide modern and proven
            services. Choose us as your professional plumbers in Toronto and the
            GTA.
          </p>
        </Grid>
        <Grid item lg={3} xs={10} sm={5} md={5}>
          <div align="center" className={classes.imageContainer}>
            <Design alt="toronto-plumbers" height="100%" width="100%" />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
